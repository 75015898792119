@import url("https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap");

* {
  padding: 0px;
  margin: 0px;
  font-family: "Manjari", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px;
}

.desktop-wrapper {
  display: block;
}
.mobile-wrapper {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .desktop-wrapper {
    display: none;
  }
  .mobile-wrapper {
    display: block;
  }
}
